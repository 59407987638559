
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_93pNr2JZdlK6Meta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_933CLmRhgrVkMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/blogg/[...slug].vue?macro=true";
import { default as indexYDyDREtVxIMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/blogg/index.vue?macro=true";
import { default as indexyj3xYUgZNfMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/checkout/index.vue?macro=true";
import { default as paymentHfGROC8XP2Meta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/checkout/payment.vue?macro=true";
import { default as index_46clientQC8H2BCbujMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/checkout/thank-you/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/var/www/projects/hillceramic.se/releases/20250411062858Z/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as indextEnit7KDcIMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/collections/index.vue?macro=true";
import { default as _91slug_93LWZpazs8a0Meta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/faq/[slug].vue?macro=true";
import { default as indexMHelsAMhzyMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/hillceramic-rabattkoder/index.vue?macro=true";
import { default as indexAuT0QSZlwbMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/index.vue?macro=true";
import { default as indexHcfNLPpvqEMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/login/index.vue?macro=true";
import { default as _91id_934Clh2mlt8nMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/qr-pr/[id].vue?macro=true";
import { default as _91_46_46_46slug_93KVm9xqofcyMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/shop/[...slug].vue?macro=true";
import { default as indexR5NnOlsKArMeta } from "/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/track/index.vue?macro=true";
export default [
  {
    name: "slug___sv",
    path: "/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/[...slug].vue")
  },
  {
    name: "blogg-slug___sv",
    path: "/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg___sv",
    path: "/blogg",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/blogg/index.vue")
  },
  {
    name: "checkout___sv",
    path: "/checkout",
    meta: indexyj3xYUgZNfMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___sv",
    path: "/checkout/payment",
    meta: paymentHfGROC8XP2Meta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-thank-you___sv",
    path: "/checkout/thank-you",
    meta: index_46clientQC8H2BCbujMeta || {},
    component: () => createClientPage(() => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/checkout/thank-you/index.client.vue"))
  },
  {
    name: "collections___sv",
    path: "/kollektioner",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/collections/index.vue")
  },
  {
    name: "faq-slug___sv",
    path: "/faq/:slug()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/faq/[slug].vue")
  },
  {
    name: "hillceramic-rabattkoder___sv",
    path: "/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "index___sv",
    path: "/",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/index.vue")
  },
  {
    name: "login___sv",
    path: "/login",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/login/index.vue")
  },
  {
    name: "qr-pr-id___sv",
    path: "/qr-pr/:id()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/qr-pr/[id].vue")
  },
  {
    name: "shop-slug___sv",
    path: "/shop/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/shop/[...slug].vue")
  },
  {
    name: "track___sv",
    path: "/track",
    meta: indexR5NnOlsKArMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20250411062858Z/pages/track/index.vue")
  }
]